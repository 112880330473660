$enable-negative-margins: true !default;

// Configuration
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import 'bootstrap/scss/functions';
// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets
@import 'bootstrap/scss/variables';
// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';

// Layout & components
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
@import 'bootstrap/scss/images';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';
//@import 'bootstrap/scss/tables';
@import 'bootstrap/scss/forms';
//@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/transitions';
//@import 'bootstrap/scss/dropdown';
//@import 'bootstrap/scss/button-group';
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/navbar';
@import 'bootstrap/scss/card';
//@import 'bootstrap/scss/accordion';
//@import 'bootstrap/scss/breadcrumb';
//@import 'bootstrap/scss/pagination';
//@import 'bootstrap/scss/badge';
//@import 'bootstrap/scss/alert';
//@import 'bootstrap/scss/progress';
@import 'bootstrap/scss/list-group';
@import 'bootstrap/scss/close';
//@import 'bootstrap/scss/toasts';
//@import 'bootstrap/scss/modal';
//@import 'bootstrap/scss/tooltip';
@import 'bootstrap/scss/popover';
@import 'bootstrap/scss/carousel';
@import 'bootstrap/scss/spinners';
@import 'bootstrap/scss/offcanvas';
//@import 'bootstrap/scss/placeholders';

// Helpers
//@import 'bootstrap/scss/helpers';

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import 'bootstrap/scss/utilities/api';





//
//// Custom.scss
//// Option B: Include parts of Bootstrap
//
//// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
//@import 'bootstrap/scss/functions';
//
//// 2. Include any default variable overrides here
//
//// 3. Include remainder of required Bootstrap stylesheets
//@import 'bootstrap/scss/variables';
//
//// 4. Include any default map overrides here
//
//// 5. Include remainder of required parts
//@import 'bootstrap/scss/maps';
//@import 'bootstrap/scss/mixins';
//@import 'bootstrap/scss/root';
//
//// 6. Optionally include any other parts as needed
//@import 'bootstrap/scss/utilities';
//@import 'bootstrap/scss/reboot';
//@import 'bootstrap/scss/type';
//@import 'bootstrap/scss/images';
//@import 'bootstrap/scss/containers';
//@import 'bootstrap/scss/grid';
//@import 'bootstrap/scss/helpers';
//
//// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
//@import 'bootstrap/scss/utilities/api';



//@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
//@import '../../../node_modules/bootstrap/scss/utilities.scss';
@import "_variables.scss";
@import "_toolbar.scss";

//@import '@angular/cdk/overlay-prebuilt.css';

// Sticky navbar

//.navbar-stuck-toggler { display: none; }
@include media-breakpoint-up(lg) {
  .navbar-sticky.navbar-stuck {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    animation: navbar-show .25s;
    box-shadow: $box-shadow;
    z-index: $zindex-sticky;

    .navbar-stuck-toggler { display: flex; }
    .navbar-stuck-barnd { display: block !important; }
    .navbar-stuck-menu {
      display: none;
      &.show { display: block; }
    }
    &.right-15 {
      right: 15px;
      width: calc(100% - 15px);
    }
  }
}

.page-title-overlap {
  //position: sticky;
  padding-bottom: 11rem;
  & + * {
    margin-top: -9rem;
  }
  @include media-breakpoint-down(md) {
    padding-bottom: 5.5rem;
    & + * {
      margin-top: -5.5rem;
    }
  }
}
//.fixed-top-fix {
//  position: relative;
//  //top: 0;
//  //right: 0;
//  //left: 0;
//  z-index: $zindex-sticky;
//}

//.home-page-header-overlap {
//  //position: sticky;
//  margin-top: -6rem !important;
//  & + * {
//    margin-top: -5rem;
//  }
//  @include media-breakpoint-down(md) {
//    padding-bottom: 5.5rem;
//  }
//}

//.fixed-top.right-15,
//.fixed-bottom.right-15 { right: 15px; }

@keyframes navbar-show {
  from {
    transform: translateY(-.625rem);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}



.card {
  font-size: .875rem;
}
//.card-header-indigo {
//  padding: 0.75rem 1.25rem;
//  margin-bottom: 0;
//  background-color: #fff;
//  border-bottom: 1px solid #eeeeee;
//}

 //.card-header-indigo .card-icon,
 //.card-header-indigo .card-text,
 //.card-header-indigo:not(.card-header-icon):not(.card-header-text),
//.bg-indigo,
//.card-rotate.bg-indigo .front,
.text-multicolor-gradient {
  //background: linear-gradient(135deg, #217bfe 0, #078efb 33%, #ac87eb 100%);
  background: linear-gradient(150deg, #7cb5ec, transparent 30%);
}

.bg-indigo {
  background: linear-gradient(60deg, #3f51b5, #0c83e2);
  //background: #3f51b5;
  //background: linear-gradient(300deg, #0c83e2, #3f51b5);
}
.bg-indigo-no-linear {
  background: #3f51b5;
  //background-color: #E6F2B4;
  //background: #8d97d4;
  //background: linear-gradient(300deg, #0c83e2, #3f51b5);
}
.bg-green {
  background: linear-gradient(60deg, #aee77b, #3b751c);
  //background: linear-gradient(300deg, #0c83e2, #3f51b5);
}
.bg-green-light-no-linear {
  //background: #aee77b;
  background-color: rgb(230, 242, 180);
}

.bg-veronese {
  background: linear-gradient(60deg, #009B7D, #009B7D);
  //background: linear-gradient(300deg, #0c83e2, #3f51b5);
}
.bg-teal {
  background: linear-gradient(60deg, #20c997, #28a745 );
  //background: linear-gradient(300deg, #0c83e2, #3f51b5);
}
.bg-purple {
  background: linear-gradient(60deg, #3f51b5, #8d97d4);
  //background: linear-gradient(300deg, #0c83e2, #3f51b5);
}
.bg-purple-linear-reverse {
  //background:  #88cc88;
  //background: linear-gradient(300deg, #3f51b5, #8d97d4);
  //background: #93B874;
  background: #407855; // Green
  //background: #038387; // Teal
  //background: #8d97d4; // Blue - !!!
  //background: #4052AB; // Indigo
  //background: #854085; // Plum
  //background: #8764B8; // Purple

  //background: linear-gradient(300deg, #0c83e2, #3f51b5);
}

.bg-purple-light-no-linear {
  background: #8599d2;
}
.bg-purple-light {
  background: #e5e3ec;
}
.bg-purple-light-1 {
  //opacity: 0.9;
  background: #d4d9ef!important;
}
//.card [class*="card-header-"] {
//  margin: 0px 15px 0;
//  padding: 0;
//  position: relative;
//}
//.card [class*="card-header-"]:not(.card-header-icon):not(.card-header-text):not(.card-header-image) {
//  border-radius: 3px;
//  margin-top: -20px;
//  padding: 15px;
//}
//.custom-dialog-container .mat-dialog-container {
//  /* add your styles */
//  z-index: 1020 !important;
//}

//.mat-select-panel {
//  background: red;
//  z-index: 1090 !important;
//}
//.cdk-overlay-container {
//  /* Do you changes here */
//  z-index: 1020 !important;
//}


// Product card

.product-card {
  padding-bottom: $card-spacer-y;
  border: 0;
  transition: $product-card-transition;

  //.product-card-actions,
  //> .btn-wishlist,
  //.badge {
  //  position: absolute;
  //  top: $spacer * .75;
  //  right: $spacer * .75;
  //  z-index: 5;
  //}
  //
  //.product-card-actions .btn-action {
  //  padding: .5rem;
  //  transition: $product-card-transition;
  //  @include border-radius($border-radius-sm);
  //  background-color: $white;
  //  font-size: $font-size-sm;
  //  visibility: hidden;
  //  opacity: 0;
  //}
  //
  //.badge {
  //  right: auto;
  //  left: $spacer * .75;
  //  &.badge-end {
  //    right: $spacer * .75;
  //    left: auto;
  //  }
  //}

  //.card-body {
  //  position: relative;
  //  background-color: $card-bg;
  //  z-index: 2;
  //}

  //.card-body-hidden {
  //  position: absolute;
  //  left: 0;
  //  top: 100%;
  //  width: 100%;
  //  margin-top: -$card-spacer-y;
  //  transition: $product-card-transition;
  //  @include border-radius($card-border-radius);
  //  border-top-left-radius: 0;
  //  border-top-right-radius: 0;
  //  background-color: $card-bg;
  //  opacity: 0;
  //  visibility: hidden;
  //  z-index: 1;
  //}

  @include media-breakpoint-up(sm) {
    padding-bottom: 0;
  }

  @include media-breakpoint-up(lg) {
    &:hover:not(.card-static) {
      border-color: $card-bg !important;
      box-shadow: $box-shadow-lg;
      z-index: 10;
      .product-card-actions .btn-action {
        opacity: 1;
        visibility: visible;
      }
      .card-body-hidden {
        opacity: 1;
        visibility: visible;
        box-shadow: $box-shadow-lg;
      }
    }
  }
}


// List variant

//.product-list .product-list-thumb {
//  @include border-radius($card-border-radius);
//  border-bottom-right-radius: 0;
//  border-bottom-left-radius: 0;
//}
//@include media-breakpoint-up(sm) {
//  .product-list {
//    .product-list-thumb {
//      width: $product-list-thumb-width;
//      @include border-radius($card-border-radius);
//      border-top-right-radius: 0;
//      border-bottom-right-radius: 0;
//      overflow: hidden;
//    }
//    .card-body-hidden {
//      top: 50%;
//      margin-top: 0;
//      transform: translateY(-50%);
//      box-shadow: none !important;
//    }
//  }
//}
//@include media-breakpoint-up(lg) {
//  .product-list:hover + .border-top { opacity: 0; }
//}


//// Alternative product card
//
//.product-card-alt {
//  border: 0;
//
//  .card-body { padding: $spacer ($spacer * .625); }
//
//  .product-thumb {
//    position: relative;
//    @include border-radius($card-border-radius);
//    overflow: hidden;
//
//    > img {
//      display: block;
//      width: 100%;
//    }
//  }
//
//  .product-thumb-overlay {
//    display: block;
//    position: absolute;
//    top: 0;
//    left: 0;
//    width: 100%;
//    height: 100%;
//    background-color: $product-card-thumb-overlay-bg;
//    z-index: 1;
//  }
//
//  .btn-wishlist {
//    top: $spacer * .75;
//    right: $spacer * .75;
//    background-color: $white;
//    z-index: 5;
//    visibility: hidden;
//  }
//  .badge {
//    position: absolute;
//    right: auto;
//    left: $spacer * .75;
//    z-index: 5;
//  }
//
//  .product-card-actions {
//    top: 50%;
//    left: 0;
//    width: 100%;
//    transform: translateY(-50%);
//    text-align: center;
//    z-index: 5;
//    visibility: hidden;
//  }
//
//  .product-thumb-overlay,
//  .btn-wishlist,
//  .product-card-actions {
//    position: absolute;
//    transition: $product-card-transition;
//    opacity: 0;
//  }
//
//  @include media-breakpoint-up(lg) {
//    .product-thumb:hover {
//      .product-thumb-overlay,
//      .btn-wishlist,
//      .product-card-actions {
//        visibility: visible;
//        opacity: 1;
//      }
//    }
//  }
//}


// Product title

.product-title > a {
  font-size:  0.875rem !important;
  font-weight: 500;
  line-height: normal;
  transition: $product-title-link-transition;
  color: $product-meta-hover-color;
  text-decoration: none !important;
  &:hover {
    color: $primary;
  }
}


// Product meta

.product-item-title {
  font-size: 13px;
  transition: $product-meta-transition;
  color: $product-meta-color;
  text-decoration: none !important;
  &:hover {
    color: $product-meta-hover-color;
  }
}

//.product-title-height {
//  line-height: normal;
//}


// Floating button

//.product-floating-btn {
//  position: absolute;
//  right: .75rem;
//  bottom: .75rem;
//  transition: opacity .2s ease-in-out;
//  opacity: 0;
//  z-index: 10;
//
//  .product-card:hover & {
//    opacity: 1;
//  }
//}


// Shop specific page title that overlaps the content below

//.page-title-overlap {
//  padding-bottom: 6.375rem;
//  & + * {
//    margin-top: -4.875rem;
//  }
//  @include media-breakpoint-down(md) {
//    padding-bottom: 5.5rem;
//  }
//}


// Interactive credit card

//.credit-card-wrapper {
//  margin: 1.875rem -.9375rem;
//  @include media-breakpoint-down(sm) {
//    .jp-card-container { width: 16.25rem !important; }
//    .jp-card { min-width: 15.625rem !important; }
//  }
//}


// Product details (Single product)

@include media-breakpoint-up(lg) {
  .product-details {
    width: 100%;
    max-width: 26rem;
    padding-top: 0.25rem;
  }
}



//.card [class*="card-header-"]:not(.card-header-icon):not(.card-header-text):not(.card-header-image) {
//  border-radius: 3px;
//  margin-top: -20px;
//  padding: 15px;
//}
//.card [class*="card-header-"] {
//  margin: 0px 15px 0;
//  padding: 0;
//  position: relative;
//}

// Product gallery

.product-gallery {
  display: flex;
}
.product-gallery-thumblist {
  margin-left: -($product-gallery-image-spacing * .8);
}
.product-gallery-thumblist-item {
  display: block;
  position: relative;
  width: $product-gallery-image-size;
  height: $product-gallery-image-size;
  margin: $product-gallery-image-spacing;
  transition: $product-gallery-image-transition;
  border: $border-width solid $border-color;
  @include border-radius($border-radius);
  text-decoration: none !important;
  overflow: hidden;
  > img {
    display: block;
    width: 100%;
    transition: opacity .2s ease-in-out;
    opacity: .6;
  }
  //.product-gallery-thumblist-item-text {
  //  position: absolute;
  //  top: 50%;
  //  width: 100%;
  //  padding: .25rem;
  //  transform: translateY(-50%);
  //  color: $product-gallery-thumb-color;
  //  font-size: $product-gallery-thumb-font-size;
  //  text-align: center;
  //  > i {
  //    display: block;
  //    margin-bottom: .25rem;
  //    font-size: 1.5em;
  //  }
  //}
  //&:hover {
  //  border-color: $product-gallery-thumb-hover-border-color;
  //  > img { opacity: 1; }
  //}
  //&.active {
  //  border-color: $product-gallery-thumb-active-border-color;
  //  > img { opacity: 1; }
  //}
}
//.product-gallery-preview {
//  position: relative;
//  width: 100%;
//  margin-top: $product-gallery-thumb-spacing;
//
//  @include media-breakpoint-up(sm) {
//    margin-left: $product-gallery-thumb-spacing;
//  }
//}
//.product-gallery-preview-item {
//  position: absolute;
//  top: 0;
//  left: 0;
//  width: 100%;
//  height: 100%;
//  transition: $product-gallery-preview-transition;
//  opacity: 0;
//  z-index: 1;
//  > img {
//    display: block;
//    width: 100%;
//  }
//  &.active {
//    position: relative;
//    opacity: 1;
//    z-index: 10;
//  }
//  &:hover { cursor: crosshair; }
//}
@include media-breakpoint-down(sm) {
  .product-gallery { display: block; }
  .product-gallery-thumblist {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: {
      right: -$spacer;
      left: -$spacer;
    }
    padding-top: $spacer;
  }
  .product-gallery-thumblist-item {
    margin: $product-gallery-image-spacing * .5;
  }
}
//.drift-zoom-pane { z-index: $zindex-fixed; }
















//.carousel-indicators {
//  position: absolute;
//  right: 0;
//  bottom: 0;
//  left: 0;
//  z-index: 2;
//  display: flex;
//  justify-content: center;
//  padding: 0;
//  margin-right: 15%;
//  margin-bottom: 1rem;
//  margin-left: 15%;
//  list-style: none;
//}

//
//.carousel-control .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right, .carousel-control .icon-next, .carousel-control .icon-prev {
//  position: absolute;
//  top: 83% !important;
//  z-index: 5;
//  display: inline-block;
//  margin-top: -10px;
//}
//h4 {
//  margin: 20px 10px 10px;
//}
//p {
//  margin: 10px;
//}
//
//#carousel-example-generic {
//  margin: 20px auto;
//  width: 400px;
//}
//
//#carouselExampleIndicators {
//  margin: 20px auto;
//  width: 400px;
//}
//
//.carousel-indicators {
//  position: absolute;
//  right: 0;
//  bottom: -60px;
//  left: 0;
//  z-index: 15;
//  display: flex;
//  justify-content: center;
//  padding-left: 0;
//  margin-right: 15%;
//  margin-left: 15%;
//  list-style: none;
//}
//
//.carousel-indicators li {
//  position: relative;
//  flex: 0 1 auto;
//  width: 70px !important;
//  height: 30px !important;
//  margin-right: 3px;
//  margin-left: 3px;
//  text-indent: -999px;
//  background-color: rgba(255, 255, 255, 0.5);
//}
//
//.carousel-indicators li::before {
//  position: absolute;
//  top: -10px;
//  left: 0;
//  display: inline-block;
//  width: 100%;
//  height: 10px;
//  content: "";
//}
//
//.carousel-indicators li::after {
//  position: absolute;
//  bottom: -10px;
//  left: 0;
//  display: inline-block;
//  width: 100%;
//  height: 10px;
//  content: "";
//}
//
//.carousel-indicators .active {
//  background-color: #ffffff;
//}

//#carouselExampleIndicators .carousel-indicators {
//  margin: 10px 0 0;
//  overflow: auto;
//  position: static;
//  text-align: left;
//  white-space: nowrap;
//  width: 100%;
//}
//#carouselExampleIndicators .carousel-indicators li {
//  background-color: transparent;
//  -webkit-border-radius: 0;
//  border-radius: 0;
//  display: inline-block;
//  height: auto;
//  margin: 0 !important;
//  width: auto;
//}
//#carouselExampleIndicators .carousel-indicators li img {
//  display: block;
//  opacity: 0.5;
//}
//#carouselExampleIndicators .carousel-indicators li.active img {
//  opacity: 1;
//}
//#carouselExampleIndicators .carousel-indicators li:hover img {
//  opacity: 0.75;
//}
//#carouselExampleIndicators .carousel-outer {
//  position: relative;
//}

//.alert {
//  //position: absolute;
//  width: 45%;
//  right: 50px;
//  top: 50px;
//}

//.material-symbols-outlined {
//  font-family: 'Material Symbols Outlined';
//  font-weight: normal;
//  font-style: normal;
//  font-size: 24px;  /* Preferred icon size */
//  display: inline-block;
//  line-height: 1;
//  text-transform: none;
//  letter-spacing: normal;
//  word-wrap: normal;
//  white-space: nowrap;
//  direction: ltr;
//}

//.mat-menu-panel {
//  min-width: 90vw !important;
//  max-width: 95vw !important;
//  margin-left: -8px;
//  margin-top: 24px;
//}
//::ng-deep.mat-menu-panel {
//  max-width: none !important;
//}


/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  //background:-webkit-linear-gradient(0, blue 50%, white 100%);
}

/* Handle */
::-webkit-scrollbar-thumb {
  //background: #d5d7e7;
  background: rgb(213, 215, 231);
  border-radius: 10px;
}

/* Handle on hover */
//::-webkit-scrollbar-thumb:hover {
//  //background: linear-gradient(180deg, #3f51b5, #0c83e2);
//  background: #3f51b5;
//}

/* Track */
::-webkit-scrollbar-track {
  //box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  //background: #eee;
}
::-webkit-scrollbar-track-piece {
  background-color: #fff;
}

/* Up */
::-webkit-scrollbar-button:single-button:vertical:decrement {
  height: 12px;
  width: 16px;
  background-position: center 4px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(213, 215, 231)'><polygon points='50,00 0,50 100,50'/></svg>");
}
::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='50,00 0,50 100,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:decrement:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='50,00 0,50 100,50'/></svg>");
}
///* Down */
//::-webkit-scrollbar-button:single-button:vertical:increment {
//  height: 12px;
//  width: 16px;
//  background-position: center 2px;
//  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 100,0 50,50'/></svg>");
//}

/* Down */
::-webkit-scrollbar-button:single-button:vertical:increment {
  height: 12px;
  width: 16px;
  background-position: center center;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(213, 215, 231)'><polygon points='0,0 100,0 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:increment:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 100,0 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:increment:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 100,0 50,50'/></svg>");
}

/* Left */
::-webkit-scrollbar-button:single-button:horizontal:decrement {
  height: 12px;
  width: 12px;
  background-position: 3px 3px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(213, 215, 231)'><polygon points='0,50 50,100 50,0'/></svg>");

}

::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,50 50,100 50,0'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:decrement:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,50 50,100 50,0'/></svg>");
}

/* Right */
::-webkit-scrollbar-button:single-button:horizontal:increment {
  height: 12px;
  width: 12px;
  background-position: 3px 3px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(213, 215, 231)'><polygon points='0,0 0,100 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 0,100 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:increment:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 0,100 50,50'/></svg>");
}

.mat-mdc-paginator {
  .mat-mdc-paginator-outer-container {
    .mat-mdc-paginator-container {
      .mat-mdc-paginator-range-actions {
        .mat-mdc-paginator-range-label {
          display: none !important;
        }
      }
    }
  }
}



.box-example-divider-white {
  width: 100%;
  height: 3rem;
  //background-color: rgba(0, 0, 0, .1);
  //border: solid rgba(0, 0, 0, .15);
  //border-width: 1px 0;
  //box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.text-header-color-bg-green-light {
  width: 100%;
  height: 3rem;
  background-color: #E6F2B4;
}

